$col-light-grey: #f4f4f4;
$col-light-grey: #e4e4e4;
$col-mid-grey: #cfcfcf;
$col-yellow: #feea00;

// ------------------------------------------------------------------------

:root {
	--bs-body-font-size: 14px;
}

input, textarea, select, button {
	font-size: var(--bs-body-font-size) !important;
	padding: 5px 10px;
}

/*
// https://stackoverflow.com/questions/21218359/why-are-my-font-awesome-icons-disappearing-on-page-loading
.fa, .fa-solid, .fa-regular {
	-webkit-animation: show 0.5s 1;
}

@-webkit-keyframes show {
	0% { opacity: 0; }
	100% { opacity: 1; }
}
*/

* {
	scrollbar-width: auto;
	scrollbar-color: #feea00 #000000;
}

*::-webkit-scrollbar {
	width: 12px;
	height: 12px;
}

*::-webkit-scrollbar-track {
	background: #000000;
}

*::-webkit-scrollbar-thumb {
	background-color: #feea00;
	border-radius: 0px;
	border: 2px solid #000000;
}

html, body {
	/*font-family: Arial, sans-serif;*/
	width: 100%;
	height: 100%;
	background: $col-light-grey;
}

* {
	transition: none !important; // Turn off all animation
}

.side-nav {
	background-color: #000;
	color: #fff;
	/*width: 180px;*/
	width: 60px;
	position: fixed;
	top: 0px;
	left: 0px;
	height: 100%;
	padding: 20px;
	display: flex;
	flex-direction: column;
	z-index: 1000;

	& .logo {
		/*display: block;*/
		display: none;
		margin: -10px -10px 10px -10px;
		max-width: calc(100% + 20px);
	}

	& .account-selector {
		margin-bottom: 20px;
		text-align: center;
		display: none;

		& select {
			text-align: center;
			padding: 0px;
			color: #fff;
			background-color: #000;
			border: none !important;
			outline: none !important;
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	& ul {
		margin: -10px;
		padding: 0px;
		list-style: none;
		font-size: 16px;
		//overflow-y: auto;
		flex: 1;
		display: flex;
		flex-direction: column;

		& a {
			text-decoration: none;
			color: #fff;
			display: block;
			padding: 10px;
		}

		& .nav-label {
			display: none;
			pointer-events: none;
		}

		& li {
			position: relative;

			&.active a,
			& a:hover {
				background-color: $col-yellow;
				color: #000;
			}

			&.bottom {
				margin-top: auto;
			}

			&:hover {
				& .nav-label {
					position: absolute;
					background: $col-yellow;
					/*width: 100px;*/
					white-space: nowrap;
					padding-right: 20px;
					top: 0px;
					left: 40px;
					height: 44px;
					display: flex;
					align-items: center;
					z-index: 10000;
					padding-left: 0px;
				}
			}
		}

		& .icon {
			margin-right: 10px;
			width: 20px;
			display: inline-block;
			text-align: center;
		}

	}

	& .search {
		margin-bottom: 30px;
		margin-left: -10px;
		margin-right: -10px;
		width: calc(100% + 20px);
		max-width: none;

		& input {
			width: 100%;
			padding: 5px 10px;
			border: 2px solid $col-yellow;
			border-radius: 0px;
		}
	}
}

main {
	margin-left: 60px;
	position: relative;

	& section {
		padding: 15px;
		background-color: #fff;
		margin-bottom: 20px;
	}
}

.warning {
	background: red;
	color: #fff;
	font-size: 30px;
	padding: 20px;
	margin-bottom: 20px;

	& .fa-solid {
		margin-right: 10px;
	}
}

.info {
	background: #f6f2c4;
	color: #948b27;
	font-size: 30px;
	padding: 20px;

	& .fa-solid {
		margin-right: 10px;
	}
}

.top-nav {
	background: #fff;
	color: #fff;
	position: fixed;
	top: 0px;
	width: 100%;
	height: 41px;
	padding: 10px;
	display: flex;
	align-items: center;
}

.main-content {
	padding: 0px 20px 20px 20px;
	min-height: 100vh;
	padding-bottom: 0px;
}

.btn {
	border-radius: 0px;
	border: none;
	outline: none;
	box-shadow: none;
}

.btn-primary {
	background-color: $col-yellow;
	color: #000;

	&:hover,
	&:active,
	&:focus {
		background-color: $col-yellow;
	}
}

.btn-secondary {
	background-color: #000;
	color: #fff;

	&:hover,
	&:active,
	&:focus {
		background-color: #000;
		color: $col-yellow;
	}
}

.btn-tertiary {
	background-color: #fff;
	border: 2px solid #ccc;

	&:hover,
	&:focus,
	&:focus-visible,
	&:active,
	&.show {
		border-color: #000 !important;
		color: #000 !important;
	}
}

.nav-pills {
	margin-bottom: -5px;

	& .nav-item {
		margin-bottom: 5px;

		&:not(:last-child) {
			margin-right: 5px;
		}

		& .nav-link {
			border: 2px solid $col-yellow;
			background-color: #ffffff;
			color: #000;
			border-radius: 0px;

			&.active {
				background-color: $col-yellow;
				border-color: $col-yellow;
				color: #000;
			}

			&:hover {
				background-color: $col-yellow;
				border-color: $col-yellow;
				color: #fff;
			}
		}
	}
}

.empty-text {
	text-align: center;
	color: #aaa;
	font-size: 24px;
}

.form-group {
	margin-bottom: 0.75rem;

	& label {
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		& .btn {
			min-width: 24px;
			max-height: 24px;
			font-size: 75%;
		}
	}

	& .search {
		display: block;

		& .search-input-container {
			position: relative;

			& button {
				position: absolute;
				top: 5px;
				right: 5px;
				height: 28px;
				padding: 0px 5px;
			}
		}

		& .no-results {
			text-align: center;
			padding: 10px;
			color: #aaa;
		}

		& .results {
			outline: 2px solid $col-yellow;
			top: 40px;
		}

		& .search-results-button {
			padding: 8px;

			& button {
				display: block;
				width: 100%;
			}
		}
	}
}

.form-label {
	font-weight: bold;
	margin-bottom: 2px;

	& .btn {
		padding: 2px 8px;
	}
}

.form-control {
	border-radius: 0px;
}

fieldset {
	margin-bottom: -1rem;
}

h2 {
	background-color: $col-yellow;
	color: #000;
	font-size: 18px;
	border-bottom: 2px solid $col-yellow;
	margin-bottom: 15px;
	padding: 10px 10px;
	display: flex;
	line-height: 100%;
	align-items: center;

	& .btn {
		background-color: #fff;
		color: #000;
		margin: -5px;
		padding: 2px 8px;
		font-size: 14px;

		&:hover {
			outline: 2px solid black;
		}
	}
}

.control-panel {
	display: flex;
	align-items: center;
	padding: 10px;

	&:not(.large) {
		//min-height: 56px;
	}

	& .alert {
		margin: 0px;
		padding: 3px 6px;
		border-radius: 0px;

		& .btn {
			height: auto !important;
			margin-left: 10px;
		}
	}

	& .control-panel-component {
		display: flex;
		align-items: center;

		&:not(:last-child) {
			margin-right: 10px;
		}

		& .btn {
			padding-top: 4px;
			height: 33px;
			padding-bottom: 4px;
		}
	}

	& .form-group {
		margin-bottom: 0px;
	}

	&.sticky {
		position: sticky;
		top: 0px;
		border-bottom: 15px solid $col-light-grey;
		margin-bottom: 0px;
		z-index: 10;
	}

	& h1 {
		margin-bottom: 0px;
		margin-top: 0px;
	}

	& .controls {
		margin-left: auto;

		& *:not(:last-child) {
			margin-right: 10px;
		}

		& label {
			width: 100px;

			&:not(:first-child) {
				margin-left: 20px;
			}
		}
	}

	& input,
	& select {
		//width: 150px;
	}

	& .react-datepicker-wrapper {
		max-width: 100px;
	}

	& .nav-link {
		padding-top: 4px;
		padding-bottom: 4px;
	}

	& .search-box {
		width: 500px;
		max-width: 50%;

		& .search {
			width: 100%;
		}

		& input {
			padding: 4px;
			width: 100%;
		}
	}
}

#root.not-logged-in {
	background: $col-mid-grey;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	& img {
		display: block;
		margin: 0px auto 20px auto;
		max-width: 100%;
	}

	& .panel {
		width: 350px;
		max-width: 100%;
		background-color: #000;
		color: #fff;
		padding: 30px;
		border-top: 20px solid $col-yellow;
	}

	& .btn {
		width: 100%;
	}

}

table.clickable {
	& tbody tr:not(.empty-text) {
		cursor: pointer;

		&:hover td {
			background-color: $col-yellow;
			color: #000 !important;
		}
	}
}

.spinner-border {
	&.inline {
		width: 1em;
		height: 1em;
		border-width: 0.15em;
	}

	&:not(.inline) {
		display: block;
		margin: auto;
	}
}

.customers-table {
	& .ref-col {
		width: 180px;
	}
}

.appts-table {
	& .view-in-day-sheet-btn,
	& .hide-btn {
		display: none;
	}

	& tr:hover {
		& .btn {
			display: inline-block;
		}
	}

	& .buttons-col {
		padding: 4px;
		width: 130px;

		& .btn {
			padding: 2px 5px;
			font-size: 11px !important;
		}
	}

	& .buttons-col-narrow {
		padding: 5px;
		width: 32px;

		& .btn {
			padding: 2px 5px;
			font-size: 11px !important;
		}
	}
}

.search {
	position: relative;
	display: inline-block;
	max-width: 100%;

	& .results {
		display: none;
		position: absolute;
		top: 35px;
		background: #fff;
		min-width: 100%;
		color: #000;
		z-index: 100;

		&.active {
			display: block;
		}
	}

	& .result {
		display: flex;
		cursor: pointer;
		padding: 10px;

		& .details {
			flex: 1;
		}

		& .name {
		}

		& .address {
			font-size: 12px;
		}

		&:hover {
			background-color: $col-yellow;
			//color: #fff;
			//& .type {
			//	color: #fff;
			//}
		}
	}
}

.search .result.customer + .result.property {
	& .details {
		padding-left: 20px;
	}
}

// Hide arrows on input[type=number]
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type=number] {
	-moz-appearance: textfield;
}

.button-panel {
	& .btn {
		&:not(:last-child) {
			margin-right: 10px;
		}
	}
}

.dropdown {
	display: inline-block;

	& input[type=checkbox] {
		margin-top: 6px;
		pointer-events: none;
	}
}

.btn-stack {
	& .btn {
		&:not(:last-child) {
			margin-bottom: 10px;
		}
	}
}

.current-account {
	position: absolute;
	top: 3px;
	left: 80px;
	font-size: 10px;
}

.checkboxes {
	& .checkbox-label {
		&:not(:last-child) {
			margin-bottom: 10px;
		}
	}
}

.dropdown-menu {
	border-radius: 0px;
}

.floating-controls {
	& > div {
		position: relative;
		height: 100%;

		& .btns {
			position: absolute;
			left: 100%;
			top: 0px;
			height: 100%;
			width: 30px; // TEMP
			display: flex;
			align-items: center;
			display: none;
		}
	}
}

tr:hover .floating-controls .btns {
	display: block;
}

.customers-to-call-table {
	& .next-call-date-col {
		width: 150px;
	}

	& .next-call-priority-col {
		width: 150px;
	}

	& .phone-col {
		width: 160px;
	}
}

.modal-content {
	border-radius: 0px;
	border: none;

	& .btn-close {
		border-radius: 0px;
		border: none;
	}
}

.edit-property-modal {
	& .modal-dialog {
		max-width: 100%;
		width: 800px;
	}

	& .sticky-table-container {
		max-height: 200px;
	}

	& .modal-header {
		flex-wrap: wrap;
	}
}

.edit-chimney-notes-modal {
	& .modal-dialog {
		max-width: 100%;
		width: 400px;
	}
}

.edit-appt-modal {
	& .modal-dialog {
		max-width: 100%;
		width: 750px;
	}

	& .total-price {
		text-align: right;

		& span {
			font-weight: bold;
		}
	}
}

.linked-item {
	& .btn {
		margin: -4px;
		display: none;
	}

	&:hover {
		& .btn {
			display: inline-block;
		}
	}
}

.appt-services-table {

	& .service-col {
	}

	& .notes-col {
		width: 250px;
	}

	& .price-col {
		width: 110px;
	}

	& tfoot {
		border: none !important;

		& tr {
			border: none !important;

			& td {
				border: none !important;
			}
		}

		& .notes-col {
			text-align: right;
		}

		& .price-col {
			padding-left: 20px;
			font-weight: bold;
		}
	}
}

.calendar-appts-table {
	& .time-col {
		width: 80px;
	}
}

.checkbox-label {
	display: flex;
	align-items: center;

	& input {
		width: 24px;
		height: 24px;
		margin-right: 10px;
	}
}

.rebook-months {
	display: flex;
	flex-wrap: wrap;
	/*margin-bottom: -10px;*/

	& > * {
		width: 16.5%;
		margin-bottom: 10px;
	}

}

// TODO replace this
input[type=checkbox],
input[type=radio] {
	appearance: auto;
}

.cust-chimneys-table {

	& input[type=checkbox] {
		vertical-align: middle;
		width: 24px;
		height: 24px;
		margin-top: 6px;
	}

	& .floor-col {
		width: 180px;
	}

	& .location-col {
		width: 200px;
	}

	& .is-in-use-col {
		width: 60px;
		text-align: center;
		white-space: nowrap;

		& input {
			display: block;
			margin: auto;
			margin-top: 7px;
		}
	}

	& .notes-col {
		width: 60px;
		text-align: center;
	}
}

.modal-backdrop ~ .modal-backdrop {
	z-index: 1055;
}

.day-sheet-page {
	display: flex;
	height: calc(100vh - 77px);

	& section {
		display: flex;
		flex-direction: column;
		overflow: hidden;
		margin-bottom: 0px !important;

		&.left {
			flex: 1;
			padding: 10px;
		}

		&.right {
			flex: 1;
			max-width: 550px;
			margin-left: 20px;
			padding: 10px;
		}
	}

	& .current-account-name {
		border: 2px solid black;
		background-color: $col-yellow;
		position: absolute;
		font-weight: bold;
		top: 65px;
		right: 50px;
		padding: 2px 10px;
		font-size: 12px;
	}

	& .current-appt {
		height: 100%;
		max-height: 100%;
		/*position: relative;*/

		& form {
			display: flex;
			flex-direction: column;
			height: 100%;

			& fieldset {
				margin: 0px;
				flex: 1;
				overflow-y: auto;
				overflow-x: hidden;
				padding: 10px;
			}

			& .control-panel {
				color: white;
				padding: 0px;
				height: auto;
				margin-top: 10px;
			}
		}

		& .warning {
			font-size: 20px;
			margin-bottom: 20px;
		}
	}

	& .rbc-calendar {
		flex: 1;
	}

	&:not(.hide-map):not(.vertical-map) .rbc-calendar {
		max-height: 394px;
	}

	&.vertical-map {
		& .left {
			flex-direction: row !important;
		}

		& .map {
			margin-left: 10px;
		}
	}

	& .map {
		flex: 1;
		margin-top: 10px;
	}
}

.day-sheet.control-panel {
	display: flex;
	align-items: center;

	& .date-picker {
		width: 110px;

		& input {
			text-align: center;
			border: none;
			box-shadow: inset 0px 0px 0px 2px $col-yellow;
		}
	}

	& .sweep-selector {
		width: auto;
		min-width: 100px;
		max-width: 150px;
	}

	& .current-date {
		font-weight: bold;
	}
}

.sweep-name {
	background: #fff;
    margin: -10px;
    display: inline-block;
    padding: 10px;
    z-index: 100;
    position: relative;

	& .fa-solid {
		position: relative;
	    top: -4px;
	}
}

.rbc-time-view {
	//overflow-y: scroll;
}

.rbc-allday-cell {
	display: none;
}

.rbc-timeslot-group {
	min-height: 30px !important;
	font-size: 10px !important;

	//&:hover {
	//	background: red;
	//
	//	& .rbc-time-slot {
	//		background: cyan;
	//	}
	//}
}

//.rbc-time-slot:hover {
//	background: yellow;
//}

.rbc-time-gutter {
	& .rbc-time-slot {
		padding-top: 5px;
	}
}

.rbc-time-header-content > .rbc-row.rbc-row-resource {
	border-bottom: none;
}

.rbc-resource-header {
	padding: 5px;
	position: relative;

	& .sweep-travel-time {
		position: absolute;
		top: 5px;
		right: 5px;
		font-weight: normal;
	}

	& .btn {
		padding: 2px 6px;
		height: 20px;
		font-size: 10px;
		margin-left: 10px;
	}
}

.rbc-time-content {
	cursor: pointer;
}

.react-datepicker-popper {
	z-index: 1000;
}

.rbc-today {
	background-color: #fff;
}

.rbc-header,
.rbc-row-resource {
	border: none;
}

.rbc-event {
	z-index: 100;

	&.appt {
		border-bottom: 1px solid #ddd !important;
	}
}

.rbc-background-event {
	z-index: 90;
}

.rbc-event,
.rbc-background-event {
	color: #000;
	border-radius: 0px;
	border: none;

	&.new-appt,
	& ~ .rbc-addons-dnd-drag-preview {
		background-color: #F0F;
		opacity: 0.5;
	}

	&.active-appt {
		box-shadow: inset 0px 0px 0px 2px #FF00FF;
	}

	&:not(.active-appt) {
		&:hover {
			box-shadow: inset 0px 0px 0px 2px black;
		}
	}

	&.travel-time-appt {
		background-color: rgba(red, 0.5);
		font-size: 12px;
		overflow-y: hidden;
		color: black;
	}

	&.non-working-time {
		background-color: rgba(#000000, 0.3);
		width: calc(100% + 10px) !important;
	}
}

.rbc-background-event {
	padding-top: 7px !important;
	padding-bottom: 0px !important;
	cursor: default;
	pointer-events: none;
}

.rbc-travel-time {
	position: absolute;
	bottom: 3px;
	right: 2px;
	font-size: 10px;
	background-color: white;
	padding: 4px;
	border-radius: 4px;
}

.appt-chimneys-table {

	& .sweep-col,
	& .nest-col,
	& .cowl-col,
	& .cctv-col {
		width: 70px;
		text-align: center;
	}

	& td {
		font-size: 90%;

		&.sweep-col,
		&.nest-col,
		&.cowl-col,
		&.cctv-col {
			padding: 9px 0px 0px 0px;

			& input[type=checkbox] {
				width: 30px;
				height: 30px;
			}

			& input[type=text] {
				text-align: center;
				border-left: none;
				border-right: none;
				border-bottom: none;
			}
		}
	}
}

.price-calc-service-types-table {
	td {
		vertical-align: middle;
	}

	& .service-type-col {
	}

	& .num-col {
		width: 80px;
		text-align: center;
	}

	& .first-price-col,
	& .second-plus-price-col {
		width: 80px;
		text-align: center;
	}
}

.rbc-event-label {
	display: none !important;
}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
	border-left: none !important;
	border-top: none !important;
	border-right: none !important;
	border-radius: 0px !important;
	padding: 4px;
}

.rbc-customer-property,
.rbc-services {
	padding: 2px;
	font-size: 13px;
	line-height: 100%;
	margin-right: 30px;
}

.rbc-non-booking-appt {
	padding: 2px;
	font-size: 16px;
	line-height: 100%;

	& .icon {
		float: right;

		&:not(:last-child) {
			margin-left: 5px;
		}
	}
}

.rbc-label {
	font-size: 13px;
}

.dropdown-menu.show {
	box-shadow: 0px 0px 0px 4px $col-yellow;
}

.dropdown-item {
	&:active {
		background-color: $col-yellow;
		color: black;
	}
}

.rbc-addons-dnd-drag-preview {
	& .rbc-travel-time {
		display: none;
	}
}

.users-table {

	& .colour-col {
		width: 30px;
	}

}

.edit-user-modal {

	& .modal-dialog {
		width: 400px;
		max-width: 100%;
	}

	&.wider {
		& .modal-dialog {
			width: 900px;
		}
	}

	& .user-rotas-table {
		
		& .day-col,
		& .is-working-col {
			vertical-align: middle;
		}

		& .is-working-col {
			width: 50px;
			text-align: center;

			& input {
				width: 30px;
				height: 30px;
				margin: 0px;
				display: block;
			}
		}

		& .start-time-col,
		& .finish-time-col {
			width: 90px;

			& input {
				max-width: 100%;
				text-align: center;
			}
		}
	}
}

input[type=color] {
	padding: 0px;
}

.service-types-table {

	& .price-col {
		width: 150px;
	}

}

.edit-price-scheme-modal {
	& .modal-dialog {
		width: 600px;
		max-width: 100%;
	}

	& .price-scheme-service-types-table {
		& td {
			vertical-align: middle;
		}

		& .price-col {
			width: 120px;
			text-align: center;

			& input {
				text-align: center;
			}
		}
	}
}

.edit-service-type-modal {
	& .service-type-price-schemes-table {
		& .price-col {
			width: 120px;
			text-align: center;
			
			& input {
				text-align: center;
			}
		}
	}
}

.list-appts-page {
	&.control-panel {
		& .date-picker {
			width: 120px;

			&:not(:last-child) {
				margin-right: 10px;
			}
		}
	}
}

.send-email-modal {
	& .modal-dialog {
		max-width: 100%;
		width: 650px;
	}

	& .email-preview {
		width: 100%;
		height: 400px;
		border: 1px solid #ccc;
	}

	& .attachments {
		margin-right: -10px;

		& .btn {
			margin-right: 10px;
		}
	}
}

.rebooks-page {

	& .month-selector {
		width: 150px;
	}

}

/*
.check-territory-result {
	position: absolute;
	top: -12px;
	font-size: 10px;
	font-weight: bold;

	&.unclaimed {
		color: darkorange;
	}

	&.claimed {
		color: red;
	}
}
*/

.day-sheet-notes {
	flex: 1;
	position: relative;

	& textarea {
		width: 100%;
		max-height: 38px !important;
		resize: none;
		position: absolute;
		top: -17px;
		z-index: 1000;

		&:focus {
			max-height: unset !important;
		}
	}
}

.error-boundary {
	padding: 20px;
	border: 2px solid red;
	height: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;

	& h1 {
		text-align: center;
		color: red;
	}

	& h2 {
		justify-content: center;
		background-color: transparent;
		border: none !important;
	}
}

.appt-products-table {
	.product-col {
	}

	& .unit-price-col,
	& .qty-col,
	& .total-price-col {
		text-align: center;

		& input {
			text-align: center;
		}
	}
	
	& .unit-price-col {
		width: 110px;
	}

	& .qty-col {
		width: 110px;
	}

	& .total-price-col {
		width: 110px;
	}

	& .actions-col {
		width: 50px;
	}
}

.appt-payments-table {

	& .date-col {
		width: 130px;
	}

	& .amount-col {
		width: 110px;
		text-align: center;

		& input {
			text-align: center;
		}
	}

	& .method-col {
		width: 120px;
	}

	& .deposit-col {
		text-align: center;
		width: 75px;
		padding-top: 12px;

		& input {
			display: inline;
			width: 24px;
			height: 24px;
			margin: 0px;
		}
	}

	& .actions-col {
		width: 50px;
	}

	& tfoot {
		font-weight: bold;
	}
}

.edit-rebook-modal {
	& .modal-dialog {
		width: 800px;
		max-width: 100%;
	}

	& .cust-chimneys-table {
		& .notes-col {
			padding: 6px;

			& button {
				height: 30px;
				font-size: 14px;
			}
		}
	}

	& hr {
		margin-top: 0px;
		margin-bottom: 20px;
	}
}


.customer-comms-table {

	& .num-col {
		width: 30px;
		text-align: center;
	}

	& .date-col {
		//width: 120px;
	}

	& .user-col {
		//width: 100px;
	}

	& .type-col {
		//width: 140px;
	}

	& .result-col {
		//width: 150px;
	}

	& .description-col {
		padding-left: 20px;
	}

	& .subject-col {
		max-width: 200px;
	}

	& .error-col {
		max-width: 400px;
	}
}

table {
	& .empty-text {
		font-size: 16px;
	}
}

.clickable-tel {
	color: inherit;
	text-decoration: none;
}

.rbc-addons-dnd-resize-ns-anchor {
	display: none !important;
}

.notifications-section {
	& p {
		text-align: center;

		& .btn {
			margin-top: 10px;
		}
	}
}

.notifications-table {

	& .event-col {
		width: 300px;
	}

	& .send-sms-col,
	& .send-email-col {
		width: 120px;
		text-align: center;

		& input {
			width: 24px;
			height: 24px;
			display: inline;
			margin-top: 8px;
		}
	}

	& .options-col {
		& div:not(:last-child) {
			margin-bottom: 5px;
		}

		& input {
			display: inline;
			margin: 0px 6px;

			&[type=number] {
				text-align: center;
				width: 50px;
			}

			&[type=text] {
				max-width: 300px;
			}
		}
	}
}

.suggested-appts {

	& .bands {

		& .band {
			margin-bottom: 10px;

			& .band-name {
				border: 2px solid black;
				padding: 10px;
				margin-bottom: 10px;
			}

			& .empty-text {
				margin: 20px 0px;
				font-size: 18px;
			}

			& .appts {
				display: flex;
				flex-wrap: wrap;
				margin: 0px -10px 10px 0px;
				list-style: none;
				padding: 0px;

				& li {
					cursor: pointer;
					width: calc(33% - 10px);
					margin: 0px 10px 10px 0px;
					border: 4px solid transparent;

					& .appt-inner {
						text-align: center;
						padding: 10px;
						border: 4px solid white;
					}

					&:hover .appt-inner,
					&.active .appt-inner {
						border-color: black;
					}
				}
			}
		}
	}

	& .btn {
		font-size: 12px !important;
	}
}

.non-booking-appt-types-table {

	& .colour-col {
		width: 50px;
		text-align: center;
	}

}

.rebooks-table {

	& .property-column {
		width: 400px;
	}
	
	& .num-chimneys {
		float: right;
	}

	& .rebook-row-comm-added {
		& td {
			background-color: orange;
		}
	}

	& .rebook-row-closed {
		& td {
			background-color: green;
		}
	}

	& .rebook-row-overdue {
		& td {
			background-color: red;
		}
	}

	& .rebook-row-not-rebooked {
		& td {
			background-color: #c6f9c4;
		}
	}
}

.payment-methods-accepted-table {
	& tr {
		cursor: pointer;
	}

	& .is-accepted-col {
		width: 30px;

		& input {
			pointer-events: none;
			display: block;
			width: 24px;
			height: 24px;
		}
	}

	& .extra-text-col {
		width: 300px;
		padding: 4px;
	}
}

.select-account-modal {
	& .modal-dialog {
		width: 400px;
	}
}

.edit-appt-form {

	& .signature {
		max-width: 100%;
		border: 2px solid $col-light-grey;
	}

	& .search .results {
		max-height: calc(100vh - 300px);
		overflow-y: scroll;
	}
}

.search-highlight {
	background-color: $col-yellow;
}

.links-table {

	& .type-col {
		width: 150px;
	}

	& .actions-col {
		width: 50px;
	}
}

.sticky-table-container {
	overflow-y: auto;
	position: relative;
	
	& th {
		position: sticky;
		top: -1px;
		background-color: #fff;
	}
}

.sticky-header {
	& th {
		position: sticky;
		top: -1px;
		background-color: #fff;
	}
}

.super-table {
	.grouping-row {
		//font-weight: bold;

		& .fa {
			margin-right: 15px;
		}
	}

	& th.sortable {
		cursor: pointer;
		user-select: none;

		& .fa {
			margin-left: 5px;
		}
	}

	& .grouping-row {
		&:hover td {
			background-color: $col-light-grey;
		}

		& td {
			cursor: pointer;
		}

		& .icon {
			margin-right: 10px;
		}
	}

	& tr.row-selected {
		& td {
			background-color: $col-yellow;
		}
	}
}

.report-table {

	& .total-row {
		font-weight: bold;
		background-color: $col-yellow;
	}

}


.map-travel-time-indicator {
	display: none;
}

.customer-comms-modal {
	& .modal-dialog {
		max-width: 100%;
		width: 1200px;
	}
}

.table > :not(caption) > * > * {
	padding: 5px;
}

.modal-header {

	& .ms-auto {
		white-space: nowrap;
	}

}

.banking-summary-table {

	& .row-grouping-0,
	& .row-grouping-1 {
		font-weight: bold;
		padding-top: 20px;
	}
	
	& .row-grouping-0 {
		font-size: 20px;
	}

	& .row-grouping-1 {
		font-size: 16px;
	}

	& input[type=checkbox] {
		width: 20px;
		height: 20px;
		display: block;
	}

	& .btn {
		padding: 0px 8px;

		& .fa-solid {
			font-size: 9px;
			position: relative;
			top: -2px;
		}
	}

	& .actions-col {
		width: 100px;

		& .btn {
			white-space: nowrap;
		}
	}
}

.non-clickable {
	pointer-events: none;
}

.edit-payment-modal {

	& .modal-dialog {
		width: 350px;
	}

}

.modal-header-buttons {
	display: flex;
	width: 100%;
	margin-top: 10px;

	& .btn {
		margin: calc(var(--bs-modal-footer-gap) * 0.5);
	}
}

.control-panel.list-appts-page {
	& select {
		width: auto;
	}
}

.price-calc-modal {
	& .modal-dialog {
		width: 1000px;
		max-width: 100%;
	}
}

.appt-editor-price-scheme {
	margin-top: 5px;
	color: blue;
	font-weight: normal;
	font-size: 13px;
}

.appt-editor-email-address {
	color: blue;
	font-size: 13px;
}

.appt-editor-important-notes {
	margin-top: 5px;
	font-weight: bold;
	color: red;
}

.edit-customer-modal {

	& .modal-dialog {
		max-width: calc(100% - 20px);
		width: 1600px;
	}

}

.list-all-customers-btn {
	display: block;
	margin: 20px auto 0px auto;
}

.edit-appt-results-modal {
	& .modal-dialog {
		max-width: 100%;
		width: 750px;
	}

	& .signature {
		max-width: 100%;
	}
}

.switch-customer-account-button {
	float: right;
    margin-top: -5px;
}

.other-services-selector {
	& .dropdown-menu {
		max-height: 350px;
		overflow-y: auto;
	}

	& .dropdown-item {
		display: flex;
		align-items: center;

		& .other-service-name {
			margin-left: 10px;
			margin-right: 80px;
		}

		& .other-service-price {
			margin-left: auto;
			color: #0000FF;
		}
	}
}

.other-services-table {
	& .price-col {
		width: 100px;
	}

	& td {
		height: 40px;

		&.service-col {
			padding: 9px;
		}

		&.price-col {
			padding: 0px;

			& input {
				border: none;
				display: block;
				width: 100px;
				padding: 9px;
			}
		}
	}

	& .actions-col {
		width: 40px;
	}
}

@media print {

	.btn {
		display: none !important;
	}

	.main-content {
		padding: 0px !important;
	}

	.side-nav {
		display: none;
	}

	main {
		margin-left: 0px;
	}

	section.control-panel {
		margin-bottom: 0px;
	}

	.selection-summary {
		display: none !important;
	}

	.banking-summary-table {
		font-size: 11px;
		
		& .row-grouping-0,
		& .row-grouping-1 {
			font-weight: bold;
			padding-top: 0px;
		}
	
		& .row-grouping-0 {
			font-size: 16px;
		}

		& .row-grouping-1 {
			font-size: 12px;
		}

		& .select-col,
		& .actions-col {
			display: none;
		}

		& td {
			padding-top: 5px !important;
			padding-bottom: 5px !important;
			padding-right: 5px !important;
		}
	}
}

.dedupe-customer-modal {
	& .modal-dialog {
		width: 1200px;
		max-width: 100%;
	}

	& .search, .search-input-container, .input {
		width: 100%;
	}

	& .customer-info-table {
		& tbody {
			& th {
				width: 20%;
			}

			& td {
				width: 40%;
			}
		}

		& input[type=checkbox],
		& input[type=radio] {
			width: 20px;
			height: 20px;
			vertical-align: middle;
			margin-right: 10px;
		}
	}
}

.move-property-modal {
	& .modal-dialog {
		width: 700px;
		max-width: 100%;
	}

	& .search, .search-input-container, .input {
		width: 100%;
	}

	& .search {
		margin-bottom: 20px;
	}
}

.nav { 
	& .nav-link {
		padding-left: 12px;
		padding-right: 12px;
	}
}

.resend-email-modal {
	& .modal-dialog {
		width: 1000px;
		max-width: 100%;
	}

	& iframe {
		width: 100%;
		height: 500px;
		border:1px solid #ccc;
	}
}